
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import isEqual from 'lodash-es/isEqual';

export class StateSubject<T> extends BehaviorSubject<T> {
  private readonly initialValue: T

  constructor(value: T) {
    super(value)
    this.initialValue = value
  }

  /***
   * Observable of the current value of the subject
   */
  get value$(): Observable<T> {
    return super.asObservable().pipe(
      distinctUntilChanged((a, b) => isEqual(a, b))
    )
  }

  /***
   * Update the current value of the subject
   * @param value
   */
  update(value: T): void {
    this.next(value)
  }

  /***
   * Reset the current value of the subject to the initial value
   */
  reset(): void {
    this.next(this.initialValue)
  }
}
