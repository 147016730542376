
import {distinctUntilChanged, map, Observable, share, shareReplay, Subject, takeUntil} from 'rxjs';
import { StateSubject } from './state-subject';
import isEqual from 'lodash-es/isEqual';

export class Store<T extends Object> {
  private readonly unsubscriber: Subject<void>
  private state: StateSubject<T>

  constructor(value: T, unsubscriber: Subject<void>) {
    this.state = new StateSubject(value)
    this.unsubscriber = unsubscriber
  }

  /***
   * Set state
   * @param value
   */
  setState(value: Partial<T>): void {
    this.state.next({ ...this.state.value, ...value })
  }

  /***
   * Get state
   */
  getState(): T {
    return this.state.value
  }

  /***
   * Select state by key
   * @param key
   */
  select<K extends keyof T>(key: K): Observable<T[K]> {
    return this.state.value$.pipe(
      map((state) => state[key]),
      distinctUntilChanged((a, b) => isEqual(a, b)),
      shareReplay(1),
      takeUntil(this.unsubscriber),
    )
  }

  /***
   * Select all state
   */
  selectAll(): Observable<T> {
    return this.state.value$.pipe(
      shareReplay(1),
      takeUntil(this.unsubscriber)
    )
  }

  /***
   * Reset state
   */
  reset(): void {
    this.state.reset()
  }
}
